import {Inject, Injectable} from '@angular/core';
import {NotificationService} from '@modules/notification';
import {translate} from '@ngneat/transloco';
import {EMPTY, Observable, of, Subject} from 'rxjs';
import {map, shareReplay, startWith, switchMap, tap} from 'rxjs/operators';

import {AppStateService, UniversalStorageService} from '@core/modules';
import {AppRouterService} from '@core/services';
import {
  FleetBoatCheckPermissionsDto,
  FleetBoatCheckPermissionsPayload,
  FleetBoatDeniedReason,
  FleetBoatPermissionsGroup,
} from '@models/boats-fleet/boat-setting-fleets';
import {UserFleetPermission, UserFleetPermissionDto} from '@models/boats-fleet/boats-fleet-permission';
import {FleetManagementApiService} from '@services/boats-fleet/fleet-management-api.service';

@Injectable({
  providedIn: 'root',
})
export class BoatFleetPermissionsService {

  private loadPermissions$: Subject<void>;
  private destroyStream$: Subject<void>;
  fleetPermissions$: Observable<UserFleetPermissionDto[]>;
  isServiceInit = false;
  canCreateFleet$: Observable<boolean | null> = this.appStateService.userFleetInfo$.pipe(map(fleet => !!fleet?.show?.create));

  constructor(
    private readonly fleetManagementApiService: FleetManagementApiService,
    @Inject(UniversalStorageService) private readonly universalStorageService: UniversalStorageService,
    private readonly appStateService: AppStateService,
    private readonly notificationService: NotificationService,
    private readonly appRouterService: AppRouterService,
  ) {
  }

  init(): void {
    if (this.isServiceInit) {
      return;
    }
    this.isServiceInit = true;
    this.loadPermissions$ = new Subject<void>();
    this.destroyStream$ = new Subject<void>();
    this.getPermissions();
  }

  destroy(): void {
    if (!this.isServiceInit) {
      return;
    }
    this.isServiceInit = false;
    this.loadPermissions$.complete();
    this.destroyStream$.next();
    this.destroyStream$.complete();
  }

  loadPermissions(): void {
    if (!this.isServiceInit) {
      return;
    }
    this.loadPermissions$.next();
  }

  findPermissionsByFleet(fleetId: number): UserFleetPermission[] {
    const permissions = this.getFleetPermissions(fleetId);
    return permissions ? permissions.permissions : [];
  }

  checkFleetPermissions(fleetId: number, userPermissions: UserFleetPermission[] | UserFleetPermission): boolean {
    const permissions = this.findPermissionsByFleet(fleetId);
    return this.isHavePermission(permissions, userPermissions);
  }

  isHavePermission(fleetPermissions: UserFleetPermission[], userPermissions: UserFleetPermission[] | UserFleetPermission): boolean {
    if (Array.isArray(userPermissions)) {
      return fleetPermissions.some(permission => userPermissions.includes(permission));
    }
    return fleetPermissions.indexOf(userPermissions) >= 0;
  }

  private getPermissions(): void {
    this.fleetPermissions$ = this.loadPermissions$.pipe(
      startWith(null),
      switchMap(() => this.fleetManagementApiService.getUserFleetPermissions()),
      tap(perms => {
        this.appStateService.fleetPermissions = perms;
      }),
      shareReplay(1),
    );
  }

  private getFleetPermissions(fleetId: number): UserFleetPermissionDto | null {
    const permissions = this.getCachedPermissions();
    if (!permissions) {
      return null;
    }
    return permissions.find(permission => permission.id === fleetId) || null;
  }

  getCachedPermissions(): UserFleetPermissionDto[] | null {
    const statePermissions = this.appStateService.fleetPermissions;
    return statePermissions?.length ?
      statePermissions : null;
  }

  checkBoatAccess(payload: FleetBoatCheckPermissionsPayload): Observable<boolean> {
    return this.fleetManagementApiService.checkBoatAccess(payload)
      .pipe(
        map(data => this.checkPermissionReason(data)),
      );
  }

  checkBoatPermissionNavigation(
    boat: number,
    group: FleetBoatPermissionsGroup,
    fn: (...args) => void,
    args: any[] = []): Observable<void> {
    return this.checkBoatAccess({boat, group})
      .pipe(
        switchMap(havePermissions => {
          if (!havePermissions) {
            return EMPTY;
          }
          return of(fn.call(this.appRouterService, ...args));
        }),
      );
  }

  private checkPermissionReason(data: FleetBoatCheckPermissionsDto): boolean {
    const {reason, access} = data;
    if (access) {
      return true;
    }
    if (reason === FleetBoatDeniedReason.FleetPermission) {
      this.notificationService.error(translate('boatsFleet.noBoatPermission'));
      return false;
    }
    if (reason === FleetBoatDeniedReason.BoatWithoutSubscription) {
      this.notificationService.error(translate('boatsFleet.noBoatSubscription'));
    }
    return false;
  }
}
